@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.banner-bg {
  background-image: url("../public/images/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.step-active {
  background: linear-gradient(180deg, #13003a 0%, rgba(19, 0, 58, 0) 100%);
  box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.08);
}

.services-bg {
  background-image: url("../public/images/bg-services.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.it-service {
  background-image: url("../public/images/it-service.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}

.contact-bg {
  background-image: url("../public/svgs/contact-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  width: 100%;
  rotate: 180deg;
}

.philosophy-bg {
  background-image: url("../public/images/philosophy.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}
/* Typewriter.css */

.cursor-animation {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 24px;
  background-color: #f10062; /* Cursor color */
  animation: blink 0.7s infinite alternate; /* Blink animation */
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes deleting-animation {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.achievement-bg {
  background-image: url("../public/images/achievement/background.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.footer-bg {
  background-image: url("../public/images/achievement/background.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

@layer base {
  .nav-items {
    @apply font-raleway text-white 2xl:text-2xl xl:text-xl text-base font-semibold hover:text-secondary transition-all ease-in-out duration-500 hover:underline underline-offset-[14px];
  }
  .nav-items-mob {
    @apply font-raleway text-lg font-semibold uppercase text-white;
  }
  .company-desc {
    @apply font-montserrat text-xl font-normal mt-8 text-center;
  }
  .company-icon {
    @apply w-24 h-24 rounded-full border border-primary text-primary font-raleway text-2xl font-semibold p-4 flex items-center justify-center;
  }
  .step-btn {
    @apply w-full h-20 flex items-center justify-center bg-[#4842f0] text-white font-raleway font-normal text-lg rounded-lg px-3;
  }
  .step-btn-active {
    @apply bg-primary w-full h-20 flex items-center justify-center text-white font-raleway font-semibold text-lg rounded-lg px-3;
  }
}
